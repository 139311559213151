<template>
  <collect-container title="请填写您的信息，私域专家将主动与您联系">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item prop="contact" label="您的称呼">
        <el-input v-model="form.contact" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="联系电话">
        <el-input v-model="form.mobile" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="user_type" label="客户类型">
        <el-select v-model="form.user_type">
          <el-option
            v-for="(item, index) in userTypes"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="您是从什么渠道了解魔筷的?"
        prop="source"
        class="learn_item"
      >
        <el-select
          v-model="form.source"
          placeholder="请选择"
          @change="where_learn"
        >
          <el-option
            v-for="(item, index) in optionData"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="learnInfo" v-if="visible">
        <el-input
          v-model="form.learnInfo"
          placeholder="请输入"
          class="learn"
        ></el-input>
      </el-form-item>
      <div class="submit-btn" @click="submit">立即提交</div>
    </el-form>
    <collect-success-modal
      :visible="modalVisible"
      @close="closeModal"
    ></collect-success-modal>
  </collect-container>
</template>

<script>
import CollectContainer from '@/components/CollectContainer.vue'
import CollectSuccessModal from '@/components/CollectSuccessModal.vue'

export default {
  components: {
    CollectContainer,
    CollectSuccessModal,
  },
  data() {
    return {
      modalVisible: false,
      userTypes: ['主播', '企业', '其他'],
      form: {
        contact: '',
        mobile: '',
        user_type: '',
        source: '',
        learnInfo: '',
      },
      rules: {
        user_type: {
          required: true,
          message: '请选择客户类型',
          trigger: 'change',
        },
        contact: {
          required: true,
          message: '请输入联系人姓名',
          trigger: 'change',
        },
        mobile: {
          required: true,
          message: '请输入联系电话',
          trigger: 'change',
        },
        source: {
          required: true,
          message: '请选择渠道',
          trigger: 'change',
        },
        learnInfo: {
          required: true,
          message: '请输入渠道',
          trigger: 'change',
        },
      },
      optionData: [
        '朋友介绍',
        '百度搜索',
        '媒体报道',
        '大型活动(行业展会、会议等)',
        '其他',
      ],
      learnInfo: '',
      visible: false,
    }
  },
  methods: {
    closeModal() {
      this.modalVisible = false
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          if (this.visible) {
            this.form.source = this.form.learnInfo
          }
          delete this.form.learnInfo
          window.lib.api.post({
            api: 'ec/a/boss/seller/agent/clue/info/add',
            data: this.form,
            success: () => {
              // this.$message.success('提交成功')
              this.$refs.form.resetFields()
              this.modalVisible = true
              this.visible = false
            },
            error: (err) => {
              this.$message.error(err.msg)
            },
          })
        }
      })
    },
    where_learn(value) {
      this.visible = value == '其他' ? true : false
      this.rules.learnInfo[0].required = this.visible ? true : false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../style/common.less';

.el-form {
  /deep/ .el-form-item {
    width: 520px;
    .el-select {
      width: 100%;
    }
  }
}
.learn_item {
  .pxToVw(margin-bottom, 20);
}
</style>
